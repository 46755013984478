<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
			<!-- 搜索框 -->
			<el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称" label-width="50px" prop="keyword">
					<el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable
						style="width: 230px" />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
					<el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click.stop="handleAdd">新增</el-button>
				<!-- <right-toolbar :show-search.sync="showSearch" @queryTable="query" /> -->
			</el-row>
			<!--表格-->
			<el-table v-loading="loading" size="small" :data="list" element-loading-text="Loading" highlight-current-row
				style="width: 100%">
				<el-table-column label="ID" width="80" align="center">
					<template slot-scope="scope">
						{{ scope.row.id }}
					</template>
				</el-table-column>
				<el-table-column label="单位" min-width="110" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="昵称" min-width="110" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.nickname }}</span>
					</template>
				</el-table-column>
				<el-table-column label="手机" min-width="110" align="center" prop="l_mobile"></el-table-column>
				<el-table-column label="状态" min-width="60" align="center">
					<template slot-scope="scope">
						<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
						<el-tag v-if="scope.row.status === 2" size="mini" type="danger">停用</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="170">
					<template slot-scope="scope">
						<i class="el-icon-time" />
						<span>{{ scope.row.ctime }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center" fixed="right">
					<template slot-scope="scope">
						<el-tooltip class="item" effect="dark" content="编辑" placement="top">
							<el-button type="success" icon="el-icon-edit" size="mini" circle
								@click.stop="handleEdit(scope.row)"></el-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" content="删除" placement="top">
							<el-button type="danger" icon="el-icon-delete" size="mini" circle
								@click.stop="handleDelete(scope.row.id)"></el-button>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
			<pagination :total="total" auto-scroll :page.sync="queryForm.page" :limit.sync="queryForm.limit"
				@pagination="query" />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
			<el-form-item label="所属单位" prop="pid">
			  <el-input v-model="company.name" placeholder="请输入所属单位" type="text" disabled clearable />
			</el-form-item>
		    <el-form-item label="账号昵称" prop="nickname">
		      <el-input v-model="form.nickname" placeholder="请输入账号昵称" type="text" clearable />
		    </el-form-item>
			<el-form-item label="手机号码" prop="l_mobile">
			  <el-input v-model="form.l_mobile" placeholder="请输入手机号码" type="text" clearable />
			</el-form-item>
			<el-form-item label="登录密码" prop="password">
			  <el-input v-model="form.password" placeholder="请输入登录密码" type="password" clearable />
			  <small class="text-danger" v-if="form.id">为空，表示不修改密码</small>
			</el-form-item>
			<el-form-item label="确认密码" prop="repassword">
			  <el-input v-model="form.repassword" placeholder="请输入确认密码" type="password" clearable />
			  <small class="text-danger" v-if="form.id">为空，表示不修改密码</small>
			</el-form-item>
		   <el-form-item label="账号状态">
		      <el-radio-group v-model="form.status">
		      	<el-radio :label="1">正常</el-radio>
		      	<el-radio :label="2">停用</el-radio>
		      </el-radio-group>
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	import Breadcrumb from '@/components/Breadcrumb';
	export default {
		inject: ['app'],
		mixins: [common],
		components: {
			GlobalTips,
			Breadcrumb
		},
		data() {
			var isMobileNumber = (rule, value, callback) => {
			      if (!value) {
			        return new Error('请输入电话号码')
			      } else {
			        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
			        const isPhone = reg.test(value)
			        value = Number(value) //转换为数字
			        if (typeof value === 'number' && !isNaN(value)) {
			          //判断是否为数字
			          value = value.toString() //转换成字符串
			          if (value.length < 0 || value.length > 12 || !isPhone) {
			            //判断是否为11位手机号
			            callback(new Error('手机号码格式不正确'))
			          } else {
			            callback()
			          }
			        } else {
			          callback(new Error('请输入电话号码'))
			        }
			      }
			}
			return {
				preUrl: 'user',
				autoRequest: false,
				queryForm: {
					page: 1,
					limit: 10,
					pid:'',
					grade:1,
					keyword: '',
				},
				form:{
					nickname:'',
					pid:'',
					l_mobile:'',
					password:'',
					repassword:'',
					status:1,
					grade:1,
					sort:100,
				},
				rules:{
					// pid: [
					//   { required: true, message: '请选择所属单位', trigger: ['blur','change'] }
					// ],
					nickname: [
					  { required: true, message: '请输入账号昵称', trigger: 'blur' }
					],
					l_mobile: [
					  { required: true, message: '请输入手机号码', trigger: 'blur' },
					  { validator: isMobileNumber, trigger: 'blur' }
					],
				},
				company:{},
			}
		},
		mounted() {
           if(this.$route.query.id){
           	 this.queryForm.pid = this.$route.query.id
           	 this.form.pid = this.$route.query.id
			 this.getList()
			 this.getCompanyInfo()
           }
		},
		methods: {
			getList() {
			  this.loading = true
			  this.axios.get(`/manage/${this.preUrl}/child_list`, {
			    params: this.queryForm
			  }).then(res => {
			    this.total = parseInt(res.data.total)
			    this.list = res.data.list
			    this.loading = false
			  }).catch(err => {
			    this.loading = false
			  })
			},
		    reset() {
		      this.form = {
		    	  nickname:'',
		    	  pid:this.$route.query.id,
		    	  l_mobile:'',
		    	  password:'',
		    	  repassword:'',
		    	  status:1,
				  grade:1,
		    	  sort:100,
		      }
		      this.resetForm('form')
		    },
			getCompanyInfo(){
				this.axios.get('/manage/user/info',{
					params:{
						id:this.form.pid
					}
				}).then(res=>{
					if(res.status){
						this.company = res.data
					}
				})
			},
			handleSubmit(){
				this.$refs.form.validate(valid => {
				  if (valid) {
					if(this.form.password != ''){
						if (this.form.password != this.form.repassword) {
						  this.$message.error('两次密码不一致')
						  return false
						}
					}
				    if (this.form.id === undefined) {
				      this.axios.post('/manage/'+this.preUrl + '/add', this.form).then(res => {
				        if (res.status) {
				          this.$message.success('编辑成功')
						  this.open = false
				          this.getList()
				        } else {
				          this.$message.error(res.msg)
				        }
				      })
				    } else {
				      this.axios.put('/manage/'+this.preUrl + '/edit/' + this.form.id, this.form).then(res => {
				        if (res.status) {
				          this.$message.success('修改成功')
						  this.open = false
				         this.getList()
				        } else {
				          this.$message.error(res.msg)
				        }
				      })
				    }
				  }
				})
			},
			downloadTemplate(){
				this.axios.get('/manage/user/downloadTemplate',{
					responseType: 'arraybuffer',
				}).then(res=>{
					  const aLink = document.createElement("a");
					  let blob = new Blob([res], { type: "application/vnd.ms-excel" });
					  aLink.href = URL.createObjectURL(blob);
					  aLink.download = '导入模板.xlsx';
					  aLink.click();
					  document.body.appendChild(aLink);
				})
			},
			importExcel(e){
				//获取用户选择的文件
				const file = e.raw
				let formdata = new FormData()
				formdata.append('file', file)
				this.axios.post('/manage/user/importExport',formdata).then(res=>{
					this.$refs.upload.clearFiles()
					if(res.status){
						this.$message.success('导入成功')
						this.getList()
					}else{
						this.$message.success('导入失败')
					}
					
				})
			},
			beforeImportUpload(file){
				  let fileArr = file.name.split('.')
				  let suffix = fileArr[fileArr.length - 1]
				  //只能导入.xls和.xlsx文件
				  if (!/(xls|xlsx)/i.test(suffix)) {
					this.$message('文件格式不正确')
					return false
				  }
				  return true
			},
			importSuccess(e){
				console.log(e)
				// 上传成功之后清除历史记录;否则只能上传一次
				this.$refs.upload.clearFiles()
			},
			importError(e){
				console.log(e)
				this.$refs.upload.clearFiles()
			},
		}
	}
</script>

<style>
</style>